import {
  AppBar,
  Button,
  Toolbar,
  Typography,
} from '@mui/material';
import GlobalStyles from '@mui/material/GlobalStyles';
import { styled } from '@mui/system';
import SwaggerUI from 'swagger-ui-react';
import Logo from './Logo';
import 'swagger-ui-react/swagger-ui.css';

const AppLogo = styled(Logo)(({ theme }) => ({
  marginRight: theme.spacing(2),
  width: '50px',
  height: '50px',
}));

const Title = styled(Typography)({
  flexGrow: 1,
  color: 'inherit',
});

const Swagger = ({ onLogout, spec, title }) => (
  <>
    <GlobalStyles styles={{
      '.swagger-ui': {
        filter: 'invert(90%) hue-rotate(180deg)',
      },
      '.swagger-ui .highlight-code': {
        filter: 'invert(100%) hue-rotate(180deg)',
      },
      '.swagger-ui .backdrop-ux': {
        filter: 'invert(90%) hue-rotate(180deg)',
      },
    }} />
    <AppBar position="relative">
      <Toolbar>
        <AppLogo />
        <Title variant="h6" noWrap>{title}</Title>
        <Button color="primary" variant="outlined" onClick={onLogout}>
          Logout
        </Button>
      </Toolbar>
      {spec && (
        <SwaggerUI
          spec={spec}
          docExpansion="list"
          supportedSubmitMethods={['get']}
        />
      )}
    </AppBar>
  </>
);

export default Swagger;
