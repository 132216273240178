import axios from 'axios';

class Api {
  constructor() {
    this.client = axios.create({
      baseURL: process.env.REACT_APP_SOCIALNET_API_URL,
      timeout: 10000,
      headers: { 'Accept': 'application/vnd.shadowdragon.v2+json' },
    });
  }

  login(apiKey) {
    return this.client.post('/login', { api_key: apiKey });
  }

  spec(token) {
    return this.client.get('/openapi', {
      headers: { 'Authorization': `Bearer ${token}` },
    });
  }
}

export default new Api();
