import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import { useSession } from './hooks';
import Swagger from './components/Swagger';
import SignIn from './components/SignIn';

const theme = createTheme({
  palette: {
    mode: 'dark',
  },
});

const App = () => {
  const session = useSession();
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {session.loggedIn ? (
        <Swagger
          title="SocialNet API"
          spec={session.spec}
          onLogout={session.logout}
        />
      ) : (
        <SignIn
          title="ShadowDragon SocialNet API"
          error={session.error}
          onLogin={session.login}
        />
      )}
    </ThemeProvider>
  );
};

export default App;
