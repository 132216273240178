import { Link, Typography } from '@mui/material';

const Copyright = () => (
  <Typography variant="body2" align="center" sx={{ color: 'text.secondary' }}>
    {'© '}
    {new Date().getFullYear()}
    {' '}
    <Link color="inherit" href="https://shadowdragon.io/" target="_blank" rel="noopener">
      ShadowDragon, LLC
    </Link>
    {'. All rights reserved.'}
  </Typography>
);

export default Copyright;
