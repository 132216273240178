import { useState } from 'react';
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
} from '@mui/material';
import { styled } from '@mui/system';
import Copyright from './Copyright';
import Logo from './Logo';

const AppLogo = styled(Logo)(({ theme }) => ({
  margin: theme.spacing(1),
  width: '100px',
  height: '100px',
}));

const Paper = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(8),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

const Form = styled('form')(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(1),
}));

const SignIn = ({ error, onLogin, title }) => {
  const [formData, setFormData] = useState({ apiKey: '' });

  const handleChange = name => e => {
    setFormData({ ...formData, [name]: e.target.value });
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (onLogin) {
      onLogin(formData);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Paper>
        <AppLogo />
        <Typography component="h1" variant="h5">
          {title}
        </Typography>
        <Form autoComplete="off" onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            type="password"
            id="api-key"
            label="API Key"
            autoFocus
            value={formData.apiKey}
            onChange={handleChange('apiKey')}
            helperText={error}
            error={!!error}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ m: (theme) => theme.spacing(3, 0, 2) }}
          >
            Sign In
          </Button>
        </Form>
      </Paper>
      <Box sx={{ mt: 8 }}>
        <Copyright />
      </Box>
    </Container>
  );
};

export default SignIn;
