import { useEffect, useState } from 'react';
import api from './api';

export const useSession = () => {
  const token = localStorage.getItem('token');

  const [loggedIn, setLoggedIn] = useState(!!token);
  const [error, setError] = useState(null);
  const [spec, setSpec] = useState(null);

  useEffect(() => {
    // If we are not logged in, ensure localStorage is empty
    // and the spec is set to null.
    if (!loggedIn) {
      localStorage.clear();
      setSpec(null);
      return;
    }

    // If the spec not empty, there no need to download it again.
    // Short-circuit to avoid unnecessary network requests.
    if (spec) {
      return;
    }

    // Download the OpenAPI spec.
    (async () => {
      const response = await api.spec(token);
      if (response.data.errors) {
        setError(response.data.errors[0].message);
        setLoggedIn(false);
        return;
      }

      setSpec(response.data.data);
    })();
  }, [loggedIn, spec, token]);

  const login = async ({ apiKey }) => {
    setError(null);

    const response = await api.login(apiKey);
    if (response.data.errors) {
      setError(response.data.errors[0].message);
      return;
    }

    localStorage.setItem('token', response.data.data.token);
    setLoggedIn(true);
  };

  const logout = () => {
    setLoggedIn(false);
  };

  return {
    loggedIn,
    error,
    spec,
    login,
    logout,
  };
};
